 <!-- ======= Header ======= -->
  <header id="header">
    <div class="container-fluid  align-items-center" style="text-align:center;max-height:80px;">

      <div class="logo">

        <!-- Uncomment below if you prefer to use an image logo -->
        <a href="index.html" ><img src="assets/img/logo.webp" alt="" class="img-fluid"></a>
      </div>



    </div>
  </header><!-- End #header -->

  <!-- ======= Hero Section ======= -->
  <section id="hero">
    <div class="hero-container">
      <h1 style="color:#f0b732;font-size:40px;">THE BLACK EXCELLENCE FAMILY ENTERTAINMENT</h1>
      <h2>We're working hard to improve our website and we'll ready to launch after</h2>
      <div class="countdown" style="font-weight:bold;">
        <span id="days"> {{daysToDday}} Days</span><br>
        <span id="hours"> {{hoursToDday}}:{{minutesToDday}}:{{secondsToDday}}   </span>

      </div>

        <div class="text-center"><a class="buttonexcellence"  href="#main">Save 10% Now!</a></div>

    </div>
  </section><!-- End Hero -->

<div class="bgimg-2">
  <div class="container parallax-curtain">
  <div class="row" style="padding:100px 0;color:black;">
  <div class="col-md-8">
    <h2 class="text-align-left"><span style="display: initial;font-weight:bold;font-size:45px;">Save 10%.</span></h2>
	<h2 class="text-align-left"><span style="display: initial;font-weight:bold;font-size:45px;">Pre-Order Sales</span></h2>

	<p class="text-align-left"><span style="display: initial;font-size:25px;">You will have the opportunity to get your hands on the All New Black Excellence Family Entertainment right before anyone else.</span></p><p class="text-align-left"><br></p><p class="text-align-left"><span style="display: initial;font-size:25px;">Be in the know!</span></p>
	<p><br/></p>

	<a class="buttonexcellence" href="#main">Buy Now</a>
   </div>
   <div class="col-md-4">
<div id="myCarousel" class="carousel slide" data-ride="carousel">


    <!-- Wrapper for slides -->
    <div class="carousel-inner">
      <div class="item active">
        <img src="assets/img/yellow.webp" alt="" style="width:100%;">
      </div>

      <div class="item">
        <img src="assets/img/blue.webp" alt="" style="width:100%;">
      </div>

      <div class="item">
        <img src="assets/img/black.webp" alt="" style="width:100%;">
      </div>

	  <div class="item">
        <img src="assets/img/red.webp" alt="" style="width:100%;">
      </div>
    </div>


  </div>

  </div>
  </div></div>
</div>
<div class="seperator"></div>
  <main id="main">

    <!-- ======= Contact Us Section ======= -->
    <section id="buynow" class="contact">
      <div class="container">

        <div class="section-title">
          <h2 class="titleclass">Buy Now in £25 With</h2>
        </div>
        <div *ngIf="infoMessage">
          <div class="alert alert-success alert-dismissible">
            <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
            {{ infoMessage }}
          </div>
        </div>
        <div style="width: 100%;text-align: center;">
        <div style="margin:0 auto" class="buttonwidth">

          <ngx-paypal [config]="payPalConfig"></ngx-paypal>
        </div>


        </div>

      </div>
    </section>
	<div class="seperator"></div>
	<section id="contact" class="contact">
      <div class="container">

        <div class="section-title">
          <h2 class="titleclass">Contact Us</h2>
        </div>

        <div class="row contact-info">

          <div class="col-md-4">
            <div class="contact-address">
              <i class="bi bi-geo-alt"></i>
              <h3>Address</h3>
              <address>London SW1A 1AA,United Kingdom</address>
            </div>
          </div>

          <div class="col-md-4">
            <div class="contact-phone">
              <i class="bi bi-phone"></i>
              <h3>Phone Number</h3>
              <p><a href="tel:+449876543210">+44 987-654-3210</a></p>
            </div>
          </div>

          <div class="col-md-4">
            <div class="contact-email">
              <i class="bi bi-envelope"></i>
              <h3>Email</h3>
              <p><a href="mailto:Support@excellenceblackedition.com">Support@excellenceblackedition.com</a></p>
            </div>
          </div>

        </div>



      </div>
    </section><!-- End Contact Us Section -->

  </main><!-- End #main -->

  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="container">
      <div class="copyright">
        &copy;  <strong><span> 2021 Excellence Black Edition</span></strong>. All Rights Reserved
      </div>

    </div>
  </footer><!-- End #footer -->


  <!-- Template Main JS File -->

