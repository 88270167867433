import { Component } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { PaypalserviceService } from './paypalservice.service';
import { first } from 'rxjs/operators';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor( private registerservice: PaypalserviceService) { }
  title = 'paypal-pwa';

  private subscription?: Subscription;
  public payPalConfig?: IPayPalConfig;
  showSuccess: any;
  purchase_units1: any;
  shippingres: any;
  namedetail: any;
  fullname: any;
  addressdetail: any;
  payerdetails: any;
  infoMessage:any;

  public dateNow = new Date();
  public dDay = new Date('July 01 2021 00:00:00');
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;


  public timeDifference: any;
  public secondsToDday: any;
  public minutesToDday: any;
  public hoursToDday: any;
  public daysToDday: any;

  ngOnInit(): void {

    this.subscription = interval(1000)
    .subscribe(x => { this.getTimeDifference(); });
    this.initConfig();
  }

  private getTimeDifference () {
    this.timeDifference = this.dDay.getTime() - new  Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
}

private allocateTimeUnits (timeDifference: any) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
}


  private initConfig(): void {
    this.payPalConfig = {
    currency: 'GBP',
    clientId: 'AXPrro8kxHk4d3sm7RSyQuuG3n3dfNtLBvHE3kWB0m_irjktkYdABIwXAtqKhFiCLsvTisY2VhsaNvcL',
    createOrderOnClient: (data) => <ICreateOrderRequest>{
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            currency_code: 'GBP',
            value: '25',
            breakdown: {
              item_total: {
                currency_code: 'GBP',
                value: '25'
              }
            }
          },
          items: [
            {
              name: 'Excellence Early Subscription',
              quantity: '1',
              category: 'DIGITAL_GOODS',
              unit_amount: {
                currency_code: 'GBP',
                value: '25',
              },
            }
          ]
        }
      ]
    },
    advanced: {
      commit: 'true',

    extraQueryParams: [ { name:"locale" ,value:"en_GB" } , { name: "enable-funding", value:"card"}  ]
    },
    style: {
      label: 'paypal',
      layout: 'vertical',
      size: 'responsive',
    },
    onApprove: (data, actions) => {
      actions.order.get().then((details: any) => {
      });
    },
    onClientAuthorization: (data) => {
      this.purchase_units1 = data.purchase_units;
      this.shippingres = this.purchase_units1[0].shipping;

      var create_time = this.shippingres.create_time;
      this.namedetail = this.shippingres.name;
      var fullname = this.namedetail.full_name;

      this.addressdetail = this.shippingres.address;
      var address_line_1 = this.addressdetail.address_line_1;
      var address_line_2= this.addressdetail.address_line_2;
      var admin_area_2 = this.addressdetail.admin_area_2;
      var admin_area_1 = this.addressdetail.admin_area_1;
      var postal_code = this.addressdetail.postal_code;
      var country_code = this.addressdetail.country_code;

      this.payerdetails = data.payer;
      var email_address = this.payerdetails.email_address;

    this.registerservice.adminusercreate(email_address,fullname,address_line_1,address_line_2,admin_area_2,admin_area_1,postal_code,country_code,create_time)
      .pipe(first())
      .subscribe(
      data => {
        console.log(data);
        this.infoMessage = 'Thank you for an order.';
      },

      error => {
      });

      this.showSuccess = true;
    },
    onCancel: (data, actions) => {
      console.log('OnCancel', data, actions);
    },
    onError: err => {
      console.log('OnError', err);
    },
    onClick: (data, actions) => {
      console.log('onClick', data, actions);
    },
  };
  }
}
