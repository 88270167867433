import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaypalserviceService {

  baseUrl:string =  "https://excellencecoming.developmentserver.work";
  Users:  any;
  constructor(private httpClient : HttpClient) { }

  public adminusercreate(email_address: any,fullname: any,address_line_1: any,address_line_2: any,admin_area_2: any,admin_area_1: any,postal_code: any,country_code: any,create_time: any) {

    return this.httpClient.post<any>(this.baseUrl + '/add-paypaldetail.php', { email_address,fullname,address_line_1,address_line_2,admin_area_2,admin_area_1,postal_code,country_code ,create_time})
    .pipe(map((Users: any) => {
    return Users;
    }));
  }
}
